'use client'

import Image from 'next/image'

export default function NotFoundPage() {
  return (
    <div className="h-full flex flex-col justify-center items-center">
      <Image
        alt="404"
        src="/404/404.png"
        className="w-64 h-64 object-contain"
        width={1000}
        height={1000}
        quality={100}
      />
      <div className="text-sukhumvit32 text-neutral-700 font-bold font-sukhumvit">
        404 Page Not Found
      </div>
    </div>
  )
}
